/**
 * @Author: siyukok
 * @Date: 2022/1/24 3:07 PM
 * @Version 1.0
 */

export default class QsUtil {
  static getUrlKey(name: string) {
    const obj = (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [''])
    if (obj[1]) {
      return decodeURIComponent(obj[1].replace(/\+/g, '%20')) || null
    } else {
      return ''
    }
  }
}
