










































































/* eslint-disable camelcase */
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import WecomUtil from '@/utils/wecom'
import TextUtil from '@/utils/text'
import QsUtil from '@/utils/qs'
import Constant from '@/constant/constant'
import Routes from '@/constant/routes'
import UserService from '@/services/user'
import ApiConst from '@/constant/api'

@Component({
  name: 'Login'
})
export default class extends Vue {
  private isWxWorkEnv = WecomUtil.isWxWorkEnv()

  private validateUsername = (rule: any, value: string, callback: Function) => {
    if (TextUtil.isEmpty(value)) {
      callback(new Error('Please enter the correct user name'))
    } else {
      callback()
    }
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value !== this.loginForm.password) {
      callback(new Error('The password is wrong'))
    } else {
      callback()
    }
  }

  private loginForm = {
    username: '',
    password: ''
  }

  private loginRules = {
    username: [{
      validator: this.validateUsername,
      trigger: 'blur'
    }],
    password: [{
      validator: this.validatePassword,
      trigger: 'blur'
    }]
  }

  private passwordType = 'password'
  private loading = false
  private redirect?: string
  private otherQuery: Dictionary<string> = {}

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
    const code = QsUtil.getUrlKey('code')
    const state = QsUtil.getUrlKey('state')
    if (!TextUtil.isEmpty(code) && state?.includes(WecomUtil.authState)) {
      window.location.href = `${process.env.VUE_APP_API_HOST}${ApiConst.WECOM_AUTH}?code=${code}&__tenant=${Constant.TENANT_ID}`
    }
  }

  async mounted() {
    if (this.loginForm.username === '') {
      (this.$refs.username as Input).focus()
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  private showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = ''
    } else {
      this.passwordType = 'password'
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus()
    })
  }

  private handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
      if (valid) {
        this.loading = true
        await UserService.getTokenByPassword(this.loginForm.username, this.loginForm.password,
          () => {
            this.loading = false
          },
          async() => {
            await this.$router.push({
              path: this.redirect || Routes.Home,
              query: this.otherQuery
            }, () => null)
          })
      } else {
        return false
      }
    })
  }

  private async handleWecomLogin() {
    WecomUtil.authWeixin(this.redirect)
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }
}
